// import external dependencies
import alpineUI from '@alpinejs/ui'
import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
import intersect from '@alpinejs/intersect'
import persist from '@alpinejs/persist'

window.Alpine = Alpine

alpineUI(Alpine)

Alpine.plugin(focus)
Alpine.plugin(collapse)
Alpine.plugin(intersect)
Alpine.plugin(persist)

// import local dependencies
import Router from "./util/Router"
import common from "./routes/common"
import home from "./routes/home"
import aboutUs from "./routes/about"

/** Populate Router instance with DOM routes */
const routes = new Router({
	// All pages
	common,
	// Home page
	home,
	// About Us page, note the change from about-us to aboutUs.
	aboutUs
})

// Load Events
jQuery(document).ready(() => {
	routes.loadEvents()
})

Alpine.start()
