<template>

	<div class="wlf-quiz__entry">
		<div class="g-container">
			<div class="wlf-quiz__entry-point wlf-quiz__entry-point--default">
				<div class="wlf-quiz__introduction">
					<h3 v-if="entryData.entryPoint.type == 'default'" class="title" v-text="entryData.entryPoint.title"></h3>
					<h3 v-else class="title" v-text="entryData.entryPoint.title"></h3>

					<div class="text" v-html="entryData.entryPoint.text"></div>
				</div>
				<div :class="'wlf-' + entryData.entryPoint.type + '-answers'">
					<button
						v-for="(answerText, answerValue) in entryData.entryPoint.answer_text"
						v-bind:key="answerValue"
						@click="entrySelected = answerValue;"
						class=""
						:class="{
							'is-active' : ( entrySelected == answerValue ),
							'text-h4' : ( entryData.entryPoint.type != 'default' ),
							[ 'wlf-' + entryData.entryPoint.type + '-answer' ] : true,
						}"
						v-html="answerText"></button>
				</div>
			</div>

			<div class="wlf-quiz__action">
				<button
					@click="submitEntry()"
					class="wlf-submit-entry s-button"
					:disabled="( ! entrySelected ) ? 'true' : null"
					:class="( entrySelected ? 'is-active' : 'disabled' )">Get Started</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, inject } from "vue";
	export default {
		props: ['entryData'],

		setup (props) {
			let entrySelected = ref('');
			let goToQuestion 	 = inject('goToQuestion');
			let userChoices 	 = inject('userChoices');

			let submitEntry = function () {

				window.dataLayer.push({
                    'event_action': 'get started',
                    'event_category': entrySelected.value,
                    'event_label': document.location.pathname,
                    'event': 'form tracking',
                    'customdimension1': entrySelected.value
                });

				if( entrySelected.value == "product_select" ) {
					props.entryData.directSelectProduct = true;
				} else if ( entrySelected.value == "confirm_product" ) {

					userChoices.value['product_select'] = [ props.entryData.selectedProduct.ID ];

					props.entryData.directSelectProduct = true;
					goToQuestion( 1, 2);
				} else {
					props.entryData.directSelectProduct = false;
				}
				props.entryData.entryCompleted = true;

			};
			return { entrySelected, submitEntry };
		},
	};
</script>

<style scoped>
	.wlf-quiz__introduction{
		text-align: center;
		max-width: 900px;
		margin: 35px auto;
	}

	.wlf-quiz__introduction h3 {
		margin-bottom: 25px;
	}

	.wlf-quiz__action {
		text-align: center;
		margin: 35px auto;
	}

	.wlf-default-answers {
		max-width: 1100px;
		margin: 30px auto;
		display: flex;
		justify-content: center;
	}

	.wlf-default-answer {
		display: block;
		margin: 15px;
		padding: 45px 45px 20px;
		border: none;
		background: white;
		flex: 1 1 0;
		border-radius: 10.26px;
		cursor: pointer;
		transition: all .4s ease;
		width: 100%;
	}
	.wlf-default-answer * {
		transition: all .4s ease;
		font-weight: 300;
	}
	.wlf-default-answer p:last-of-type{
		margin: 0;
	}

	.wlf-default-answer.is-active {
		--color__heading-text: white;
		--color__body-text: white;
		background: var(--blue);
	}


	.wlf-default-answer:hover {
		transform: scale(1.05);
	}

	.wlf-product_confirmation-answers {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.wlf-product_confirmation-answer {
		background: white;
		padding: 25px 35px;
		border-radius: 10.26px;
		border: none;
		cursor: pointer;
		margin: 15px;
		transition: all 0.4s ease;
	}

	.wlf-product_confirmation-answer.is-active,
	.wlf-product_confirmation-answer:hover,
	.wlf-product_confirmation-answer:focus {
		color: white;
		background: var(--blue);
	}

	@media screen and (max-width: 1100px){

		.wlf-default-answer {
			padding: 20px;
		}

	}
	@media screen and (max-width: 900px){
		.wlf-default-answers{
			display: block;
		}
		.wlf-default-answer {
			margin: 20px 0;
		}

	}
</style>
