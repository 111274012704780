import fitVids from "fitvids";

/**
 * Javascript that is globally applies slick carousel to anything with the .j-slides class
 */

export function fitVidsBlocks() {
	/**
	 * initializeBlock
	 *
	 * Adds custom JavaScript to the block HTML.
	 *
	 * @date    15/4/19
	 * @since   1.0.0
	 *
	 * @param   object $block The block jQuery element.
	 * @param   object attributes The block attributes (only available when editing).
	 * @return  void
	 */
	var initializeBlock = function() {
		fitVids();
	};
	// Initialize each block on page load (front end).
	initializeBlock();
	// Initialize dynamic block preview (editor).
	if (window.acf) {
		window.acf.addAction("render_block_preview", initializeBlock);
	}
}
