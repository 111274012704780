<template>
	<div class="wlf-controls">
		<div class="g-container wlf-controls__container">
			<div class="wlf-controls__direction">
				<button
					class="s-button s-button--white s-button--reverse"
					:class="{
						disabled: previousButtonStatus == 'disabled',
						active: previousButtonStatus == 'active'
					}"
					:disabled="
						previousButtonStatus == 'disabled' ? 'disabled' : false
					"
					v-on:click="previousQuestion"
					aria-label="Previous Step"
				>
					Back
				</button>
			</div>
			<div class="wlf-nav">
				<div class="wlf-nav__bar">
					<div
						class="wlf-nav__bar__inner"
						:style="'width: ' + currentDot + 'px'"
					></div>
				</div>
				<template
					v-for="(quizLevel, levelIndex) in quizPath"
					v-bind:key="quizLevel.slug"
				>
					<button
						class="wlf-nav__level"
						@click="
							levelIndex < currentLevel
								? goToQuestion(1, levelIndex + 1)
								: null
						"
						aria-label=""
					>
						<div
							class="wlf-nav__dot wlf-nav__dot--large"
							:class="{
								is_current: levelIndex == currentLevel - 1,
								is_past: levelIndex < currentLevel
							}"
						>
							<span
								class="wlf-nav__dot__inner"
								v-if="levelIndex + 1 < currentLevel"
							>
								<svg
									width="18"
									height="14"
									viewBox="0 0 18 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M6.00016 11.1701L1.83016 7.00009L0.410156 8.41009L6.00016 14.0001L18.0002 2.00009L16.5902 0.590088L6.00016 11.1701Z"
										fill="currentColor"
									/>
								</svg>
							</span>
							<span
								class="wlf-nav__dot__inner"
								v-else
								v-text="levelIndex + 1"
							></span>
						</div>
						<p class="wlf-nav__text" v-text="quizLevel.title"></p>
					</button>

					<template
						v-for="(questions,
						questionIndex) in quizLevel.questions"
						v-bind:key="questions.title"
					>
						<button
							v-if="
								levelIndex < currentLevel - 1 ||
									(levelIndex <= currentLevel - 1 &&
										questionIndex < currentQuestion)
							"
							class="wlf-nav__dot wlf-nav__dot--small"
							:data-question="
								'wlf-nav__dot--' +
									(levelIndex + 1) +
									'--' +
									(questionIndex + 1)
							"
							:class="{
								is_current_level:
									levelIndex == currentLevel - 1,
								is_past:
									levelIndex < currentLevel - 1 ||
									(levelIndex <= currentLevel - 1 &&
										questionIndex < currentQuestion - 1),
								is_current:
									levelIndex == currentLevel - 1 &&
									questionIndex == currentQuestion - 1
							}"
							@click="
								levelIndex < currentLevel - 1 ||
								(levelIndex <= currentLevel - 1 &&
									questionIndex < currentQuestion)
									? goToQuestion(
											questionIndex + 1,
											levelIndex + 1
									  )
									: null
							"
							:aria-label="'go to ' + questionIndex + 1"
						></button>

						<span
							v-else
							class="wlf-nav__dot wlf-nav__dot--small"
							:class="{
								is_current_level: levelIndex == currentLevel - 1
							}"
							:data-question="
								'wlf-nav__dot--' +
									(levelIndex + 1) +
									'--' +
									(questionIndex + 1)
							"
						></span>
					</template>
				</template>
				<div class="wlf-nav__level" v-if="hasResultsPage">
					<div
						class="wlf-nav__dot wlf-nav__dot--large"
						:class="{
							is_current: quizPath.length + 1 == currentLevel
						}"
					>
						<span
							class="wlf-nav__dot__inner"
							v-text="
								quizPath.length + 1 < currentLevel
									? 'CHECK'
									: quizPath.length + 1
							"
						></span>
					</div>
					<p class="wlf-nav__text">Review</p>
				</div>
			</div>

			<div class="wlf-controls__direction" style="text-align: right;">
				<button
					v-on:click="
						nextButtonStatus != 'disabled' ? nextQuestion() : null
					"
					class="s-button j-get-started__next-button"
					:disabled="
						nextButtonStatus == 'disabled' ? 'disabled' : false
					"
					:class="{
						'skip s-button--white': nextButtonStatus == 'skip',
						disabled: nextButtonStatus == 'disabled',
						active: nextButtonStatus == 'active'
					}"
					v-text="nextButtonText"
					aria-label="next step"
				></button>
			</div>
		</div>
	</div>
</template>

<script>
// import { currentStep } from './components/QuizDatavue'
import { inject, ref, computed, watch } from "vue";

export default {
	props: {
		quizPath: Array
	},
	setup: props => {
		let nextQuestion = inject("nextQuestion");
		let previousQuestion = inject("previousQuestion");
		let goToQuestion = inject("goToQuestion");

		let hasResultsPage = inject("hasResultsPage");

		let currentLevel = inject("currentLevel");
		let currentQuestion = inject("currentQuestion");

		let totalLevelCount = inject("totalLevelCount");
		let currentLevelQuestionCount = inject("currentLevelQuestionCount");

		let currentQuestionData = inject("currentQuestionData");

		let userChoices = inject("userChoices");
		let windowWidth = inject("windowWidth");

		let currentDotObject = computed(() =>
			jQuery(
				'[data-question="wlf-nav__dot--' +
					currentLevel.value +
					"--" +
					currentQuestion.value +
					'"]'
			)
		);
		let currentDotPosition = ref(
			currentDotObject.value.length > 0
				? currentDotObject.value.position()
				: false
		);

		let currentQuestionFollowUpFlag = false;

		let currentDot = computed(() => {
			let offsetWidth = 0;
			if (currentDotPosition.value) {
				offsetWidth = currentDotPosition.value.left;
			}
			return offsetWidth;
		});

		watch(windowWidth, (newWidth, prevWidth) => {
			currentDotPosition.value =
				currentDotObject.value.length > 0
					? currentDotObject.value.position()
					: false;
		});

		watch(currentDotObject, (newCurrentDotObject, prevCurrentDotObject) => {
			currentDotPosition.value =
				currentDotObject.value.length > 0
					? currentDotObject.value.position()
					: false;
		});

		let isEmail = email => {
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		};

		let isPhone = phone => {
			const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
			return re.test(String(phone).toLowerCase());
		};

		let previousButtonStatus = computed(() => {
			if (
				GET_STARTED.quizData.skip_entry &&
				currentLevel.value == 1 &&
				currentQuestion.value == 1
			) {
				return "disabled";
			}
			return "active";
		});

		let nextButtonStatus = computed(() => {
			let choices =
				userChoices.value[currentQuestionData.value.question_key];
			let status = "skip";

			// If there are choices made.
			if (
				(currentQuestionData.value.question_key != "name" &&
					currentQuestionData.value.question_key != "email" &&
					currentQuestionData.value.question_key != "phone" &&
					choices &&
					choices.length > 0) ||
				(currentQuestionData.value.question_key == "name" &&
					userChoices.value["firstname"] &&
					userChoices.value["firstname"].length > 0 &&
					userChoices.value["lastname"] &&
					userChoices.value["lastname"].length > 0) ||
				(currentQuestionData.value.question_key == "email" &&
					userChoices.value["email"] &&
					userChoices.value["email"].length > 0 &&
					isEmail(userChoices.value["email"])) ||
				(currentQuestionData.value.question_key == "phone" &&
					userChoices.value["phone"] &&
					userChoices.value["phone"].length > 0 &&
					isPhone(userChoices.value["phone"]))
			) {
				status = "active";
			} else {
				if (currentQuestionData.value.required) {
					status = "disabled";
				}
			}

			if (currentLevel.value == totalLevelCount.value + 1) {
				status = "complete";
			}

			return status;
		});

		let nextButtonText = computed(() => {
			let nextButtonText = "Continue";

			if (nextButtonStatus.value == "skip") {
				nextButtonText = "Skip";
			}

			if (
				currentLevel.value == totalLevelCount.value &&
				currentQuestion.value == currentLevelQuestionCount.value
			) {
				if (hasResultsPage) {
					nextButtonText = "Review";
				} else {
					nextButtonText = "Complete";
				}
			}

			if (currentLevel.value == totalLevelCount.value + 1) {
				nextButtonText = "Complete";
			}

			return nextButtonText;
		});

		let totalSectionCount = computed(() => {
			let adjuster = hasResultsPage ? 1 : 0;
			return totalLevelCount.value + adjuster;
		});

		return {
			nextQuestion,
			previousQuestion,
			currentLevel,
			currentQuestion,
			goToQuestion,
			currentDot,
			currentDotObject,
			previousButtonStatus,
			nextButtonStatus,
			nextButtonText,
			hasResultsPage,
			windowWidth
		};
	}
};
</script>

<style type="scss">
.wlf-controls__container,
.wlf-nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
}
.wlf-nav {
	flex: 1 1 0;
	margin: 25px clamp(15px, 2.5vw, 40px);
}
.wlf-nav__bar {
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	top: 50%;
	transform: translateY(-50%);
	height: 2px;
	background: #aec7ce;
}
.wlf-nav__bar__inner {
	transition: all 0.5s ease;
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 0px;
	background: var(--green);
}

.wlf-nav__level {
	position: relative;
	border: none;
	background: none;
	padding: 0;
}

.wlf-nav__text {
	position: absolute;
	top: calc(100% + 10px);
	left: 50%;
	transform: translateX(-50%);
	font-size: 14px;
	line-height: 150%;
	white-space: nowrap;
}
.wlf-nav__dot--large {
	width: 40px;
	height: 40px;
	position: relative;
	border-radius: 50%;
	background: var(--green);
	color: white;
	background: var(--white);
	color: var(--charcoal);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.4s ease;
}
.wlf-nav__dot__inner {
	color: inherit;
}
.wlf-nav__dot__inner svg {
	display: block;
}

.wlf-nav__dot--small {
	width: 14px;
	height: 14px;
	display: block;
	background: var(--green);
	background: #aec7ce;
	border-radius: 50%;
	border: none;
	appearance: none;
	transition: all 0.4s ease;
	transform: scale(1);
}

.wlf-nav__dot.is_past,
.wlf-nav__dot.is_current {
	background: var(--green);
	color: white;
}
.wlf-nav__dot.is_current {
}

.wlf-nav__dot.is_past {
	cursor: pointer;
}

.wlf-nav__dot--small.is_past:focus,
.wlf-nav__dot--small.is_past:hover {
	transform: scale(1.71);
}

.wlf-controls__direction {
	/* min-width: 150px; */
}
@media screen and (max-width: 900px) {
	.wlf-nav__dot--large {
		width: 38px;
		height: 38px;
	}
	.wlf-nav__dot--small {
		width: 12px;
		height: 12px;
	}
}
@media screen and (max-width: 800px) {
	.wlf-controls__container {
		flex-wrap: wrap;
	}
	.wlf-nav {
		width: 100%;
		order: 1;
		flex: auto;
		margin: 25px 0px 65px;
	}
	.wlf-controls__direction {
		order: 2;
		width: 50%;
	}

	.wlf-nav__dot--small {
		display: none;
	}
	.wlf-nav__dot--small.is_current_level {
		display: block;
	}
}

@media screen and (max-width: 768px) {
	.wlf-nav__dot--small {
		width: 10px;
		height: 10px;
	}
	.wlf-nav__dot--large {
		width: 34px;
		height: 34px;
	}
	.wlf-nav__text {
		display: none;
	}
	.is_current + .wlf-nav__text {
		display: block;
	}

	.wlf-nav__text {
		font-size: 12px;
	}
}

.wlf-nav__dot.is_current_level {
	animation: bubble 0.3s linear forward 0.3s;
}

@keyframes bubble {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}
</style>
