<template>
	<div>
		<div class="question-body">
			<h2 class="question-title">{{ currentQuestionData.title }}</h2>
			<div class="question-text" v-html="(currentQuestionData) ? currentQuestionData.text : null"></div>
		</div>
		<div class="v-quiz__actions" :class="'v-quiz__actions--' + currentQuestionData.question_key">
			<get-started-question-answers v-if="!currentQuestionData.answerType || currentQuestionData.answerType != 'form' " :currentQuestionData="currentQuestionData" />
			<get-started-question-form-input v-else :currentQuestionData="currentQuestionData"/>
		</div>
		<div v-if="(currentQuestionData) && currentQuestionData.disclaimer != ''" class="question-footer">
			<div class="question-disclaimer" v-html="currentQuestionData.disclaimer"></div>
		</div>
	</div>
</template>

<script>
import { ref , inject } from "vue";
import GetStartedNavigation from "./GetStartedNavigation.vue";
import GetStartedQuestionAnswers from "./GetStartedQuestionAnswers.vue";
import GetStartedQuestionFormInput from "./GetStartedQuestionFormInput.vue";

export default {
	components: {
		GetStartedNavigation,
		GetStartedQuestionAnswers,
		GetStartedQuestionFormInput
	},
	props: ['currentQuestionData'],
	setup: function(props) {
		return;
	},
};
</script>

<style type="scss" scoped>

	.question-text {
		max-width: 950px;
		margin: 0 auto;
	}

	.question-footer {
		margin-top: 40px;
	}

	.v-quiz__actions{
		max-width: 1150px;
		margin: 0 auto;
		width: 100%;
	}
</style>
