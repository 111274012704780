<template>
	<div class="g-container">
		<div class="question-body">
			<h2 class="question-title" v-text="introductionContent.title"></h2>
			<div class="question-text" v-html="introductionContent.text"></div>
		</div>
		<div class="v-quiz__review" v-if="hasPersonalInformation">
			<div class="v-quiz__review__inner">
				<h4
					v-text="introductionContent.review_title"
					v-show="introductionContent.review_title"
				></h4>
				<div class="v-quiz__review__items">
					<p class="v-quiz__review__item text-small">
						<span class="v-quiz__review__item__name">Name: </span>
						<span
							class="v-quiz__review__item__value"
							v-text="
								userChoices.firstname +
									' ' +
									userChoices.lastname
							"
						></span>
					</p>
					<p class="v-quiz__review__item text-small">
						<span class="v-quiz__review__item__name">Email: </span>
						<span
							class="v-quiz__review__item__value"
							v-text="userChoices.email"
						></span>
					</p>
					<p class="v-quiz__review__item text-small">
						<span class="v-quiz__review__item__name">Phone: </span>
						<span
							class="v-quiz__review__item__value"
							v-text="userChoices.phone"
						></span>
					</p>
					<p class="v-quiz__review__item text-small">
						<span class="v-quiz__review__item__name"
							>Province:
						</span>
						<span
							class="v-quiz__review__item__value"
							v-text="userChoices.province[0]"
						></span>
					</p>
					<p class="v-quiz__review__item text-small">
						<span class="v-quiz__review__item__name"
							>Current Member:
						</span>
						<span
							class="v-quiz__review__item__value"
							v-text="userChoices.current_member[0]"
						></span>
					</p>
				</div>
			</div>
		</div>
		<div class="v-quiz__bottom" v-html="introductionContent.sub_text"></div>
	</div>
</template>

<script>
import { inject, ref, computed } from "vue";

export default {
	setup: () => {
		let userChoices = inject("userChoices");

		let introductionContent = GET_STARTED.quizData.review;

		let hasPersonalInformation = computed(() => {
			return !GET_STARTED.quizData.skip_your_information;
		});

		return { introductionContent, userChoices, hasPersonalInformation };
		// return false;
	}
};
</script>

<style type="scss" scoped>
.question-title {
	text-align: center;
}

.v-quiz__review__inner {
	max-width: 675px;
	margin: 45px auto 30px;
	background: white;
	border-radius: 30px;
	padding: clamp(20px, 2vw, 70px);
}
.v-quiz__review__item__name {
	font-weight: 500;
}
.v-quiz__review__item {
	margin-bottom: 5px;
}
</style>
