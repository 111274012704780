<template>
	<!--  NEED HELP POPOUT  -->
	<div class="need-help__cover pos-fixed" @click="isOpen = false" v-if="isOpen"></div>
	<div class="need-help pos-absolute transition" v-bind:class="(isOpen ? 'is_open' : 'is_closed' )" >

		<button class="need-help__inner" 
		tabindex="0" 
        @click="isOpen = true">

			<a class="need-help__title text-body-2 color-blue" v-if="content.title">{{ content.title }}</a>

			<div class="need-help__expanded__container">

				<div class="need-help__expanded">

					<p class="need-help__text" v-if="content.text">{{ content.text }}</p>

					<div class="need-help__links" v-if="content.links">
						<div class="need-help__link" v-for="link in content.links" v-bind:key="link.link_text">
							<a v-if="link.link"
							v-bind:href="link.link.url" 
							v-bind:title="link.link.title" 
							v-bind:target="link.link.target"
							class="need-help__link__tag">
								{{link.link_text}}
								<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1 5.50003H10.8519M10.8519 5.50003L6.33318 0.981323M10.8519 5.50003L6.33318 10.0187" stroke="#8DC63F" stroke-width="1.63544" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</a>
						</div>
					</div>
				</div>
			</div>
		</button>
		<button class="need-help__close" v-if="isOpen == true" @click="isOpen = false" aria-label="close">
			<svg width="12" height="12" viewBox="0 0 12 12" fill="none">	
				<path d="M11 1.25L1.5 10.75" stroke="#414042" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M11 10.75L1.5 1.25" stroke="#414042" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</button>
	</div>
</template>

<script>
	import { inject } from "vue";
	export default {
		setup () {
			const content = GET_STARTED.need_help; // Localized Data (lib/get-started.php)
			let isOpen = inject('helpActive', false);
			

			return {
				isOpen, content
			}
		},
	};
</script>

<style scoped>
	.need-help {
		border-radius: 24px;
		background: white;
		cursor: pointer;
		right: 70px;
		z-index: 10;
	}
	.need-help__cover{
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
	}
	.need-help__inner {
		padding: 10px 20px;
		background: none; 
		text-align: left;
		border: none;
		cursor: pointer;
		transition: all 0.4s ease;
	}
	.is_closed .need-help__inner:hover,
	.is_closed .need-help__inner:focus {
		color: var(--charcoal);
	}
	.need-content.is_open {
		cursor: default;
	}
	.need-help__expanded__container {
		width: 0;
		overflow: hidden;
		max-height: 0;
		opacity: 0;
		visibility: hidden;

		max-width: 100%;
		transition: all 0.3s ease;
	}
	.is_open .need-help__expanded__container {
		opacity: 1;
		max-height: 400px;
		visibility: visible;
		width: 350px;
	}
	.need-help__expanded {
		width: 350px;
		max-width: 100%;
		border-top: solid 2px #AEC7CE;
		margin: 15px 0 15px;
		padding: 15px 0 0;
		transition: all .4s ease-in-out;
	}
	.need-help__title{
		margin:0;
	}
	.need-help__close {
		transition: all .4s ease-in-out;
		position: absolute;
		top: 16px;
		right: 20px;
		background: none;
		border: none;
		outline: none;
		appearance: none;
		padding: 0;
		cursor: pointer;
	}
	.need-help__close:hover {
		opacity: 0.5;
	}

	.need-help__link{
		margin: 15px 0;
	}
	.need-help__link__tag{
		position: relative;
	}
	.need-help__link__tag svg{
		position: relative;
		transition: all 0.3s ease;
		margin-left: 0;
	}
	.need-help__link__tag:hover svg{
		margin-left: 5px;
	}

	@media screen and (max-width: 900px){
		.need-help{
			right: 55px;
			z-index: 200;
		}	
		.need-help.is_open {
			right: 0;
			width: 85vw;
		}
		.need-help__inner {
			padding: 8px 20px;	
		}
	}
</style>