<template>
	<div class="wlf-form-input">
		<div v-if="currentQuestionData.question_key == 'name'" class="wlf-form-input-name">
			<div class="wlf-form-input__row">
				<div class="wlf-form-input__field">
					<input ref="firstElement" data-first-element v-on:keyup="keyMonitor" type="text" class="first-name" v-model="userChoices['firstname']" :placeholder="currentQuestionData.first_name_label">
					<div>
						<label for="" v-text="currentQuestionData.first_name_label"></label>
					</div>
				</div>
				<div class="wlf-form-input__field">
					<input type="text"  v-on:keyup="keyMonitor" class="first-name" v-model="userChoices['lastname']" :placeholder="currentQuestionData.last_name_label">
					<div>
						<label for="" v-text="currentQuestionData.last_name_label"></label>
					</div>
				</div>
			</div>
		</div>
		<div v-if="currentQuestionData.question_key == 'email'" class="wlf-form-input-email">
			<div class="wlf-form-input__row">
				<div class="wlf-form-input__field">
					<input ref="firstElement" data-first-element v-on:keyup="keyMonitor"  type="text" class="email" v-model="userChoices['email']" :placeholder="currentQuestionData.email_label">
					<div>
						<label for="" v-text="currentQuestionData.email_label"></label>
					</div>
				</div>
			</div>
			<div class="wlf-form-input__row">
				<div class="wlf-form-input__check">
					<label for="v-get-started--newsletter" class="wlf-form-checkbox">
						<input id="v-get-started--newsletter" v-model="userChoices['marketing_consent']" type="checkbox" >
						<span class="text-body wlf-form-input__check__text" v-text="currentQuestionData.newsletter_signup_label"></span>
					</label>
				</div>
			</div>
		</div>
		<div v-if="currentQuestionData.question_key == 'phone'" class="wlf-form-input-phone">
			<div class="wlf-form-input__row">
				<div class="wlf-form-input__field">
					<input ref="firstElement" data-first-element v-on:keyup="keyMonitor"  type="text" class="phone" v-model="userChoices['phone']" :placeholder="currentQuestionData.phone_label">
					<div>
						<label for="" v-text="currentQuestionData.phone_label"></label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { inject } from "vue";
	export default {
		props: ['currentQuestionData'],
		setup: function(props) {
    		let userChoices 	= inject( 'userChoices');
    		let firstElement 	= inject( 'firstElement');

			let keyMonitor = function (event){
				if(event.key == "Enter") {
					jQuery('.j-get-started__next-button').click();
				}
			};

			return { userChoices, firstElement, keyMonitor };
		},
	};
</script>

<style type="scss" scoped>

	.wlf-form-input__row {
		display: flex;
		justify-content: center;
		max-width: 1020px;
		margin: 0 auto;

	}

	.wlf-form-input__field {
		padding: 30px;
		flex: 1 1 0;
		text-align: left;
	}

	.wlf-form-input__field input {
		background: none;
		border: none;
		border-bottom: solid 1px #AEC7CE;
		margin-bottom: 15px;
		font-weight: 300;
		font-size: 39px;
		color: var(--charcoal);
		transition: all 0.4s ease;
		width: 100%;
	}
	.wlf-form-input__field input:hover,
	.wlf-form-input__field input:focus {
		border-bottom: solid 1px var(--blue);
	}

	div.wlf-form-input__field label {
		text-align: left;
		font-weight: 300;
		display: block;
	}

	.wlf-form-input__check {
		padding: 0 30px;
		width: 100%;
		text-align: left;
	}

	.wlf-form-checkbox {
		max-width: 700px;
		display: block;
		padding-left: 35px;
		position: relative;
	}

	.wlf-form-checkbox input{
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}


	.wlf-form-input__check__text::before,
	.wlf-form-input__check__text::after {
		content: '';
		display: block;
		width: 15px;
		height: 15px;
		border-radius: 4px;		
		position: absolute;
		cursor: pointer;
	}	

	.wlf-form-input__check__text::before{
		border: solid 2px var(--blue);
		width: 16px;
		height: 16px;
		top: 10px;
		left: 0;
	}

	.wlf-form-input__check__text::after {
		transition: all 0.3s ease;
		background: var(--blue);
		height: 12px;
		width: 12px;
		opacity: 0;
		left: 4px;
		top: 14px;
	}
	.wlf-form-checkbox input:checked + .wlf-form-input__check__text::after{
		opacity: 1;
	}


	@media screen and (max-width: 900px){
		.wlf-form-input__row {
			display: block;

		}

		.wlf-form-input__field {
			padding: 15px 0;
		}

		.wlf-form-input__field input {
			font-size: 20px;
		}
	}



</style>
