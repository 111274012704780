<template>
	<!-- APP HEADER  -->
	<div class="header">
		<div class="g-container g-row flex-between align-center">
			<div class="logo" v-html="logo"></div>
			<div class="body g-row flex-end align-start pos-relative">
				<get-started-help/>
			</div>
		</div>
	</div>
</template>

<script>
	import GetStartedHelp from './GetStartedHelp.vue'
	import { inject } from "vue";

	export default {
		components: {
			GetStartedHelp,
		},

		setup () {
			const logo = GET_STARTED.logo; // Localized Data (lib/get-started.php)
			return {
				logo
			}
		},
	};
</script>

<style scoped>
	.hamburger {
		width: 50px;
		height: 50px;
	}

	@media screen and (max-width: 900px){
		.hamburger {
			width: 44px;
			height: 44px;
		}
		.logo{
			max-width: 50%;
			padding-right: 15px;
		}
	}

	@media screen and (max-width: 768px){

		.logo{
			width: 160px;
		}
	}
</style>


<style>
	.v-get-started-logo {
		display: block;
		max-width: 100%;
	}
	.v-get-started-logo svg {
		max-width: 100%;
	}
</style>
