<template>
	<div
		class="app page-bursts style-3 g-cell-horizontal open--app"
		v-bind:class="{ 'open--help': helpActive }"
	>
		<get-started-header />
		<div class="body pos-relative transition">
			<get-started-quiz />
		</div>
	</div>
</template>

<script>
import GetStartedHeader from "./GetStartedHeader.vue";
import GetStartedQuiz from "./GetStartedQuiz.vue";

import {
	ref,
	defineComponent,
	provide,
	watch,
	onMounted,
	onUnmounted,
	computed
} from "vue";
// Define the component
export default defineComponent({
	name: "GetStarted",
	// Register components
	components: {
		GetStartedHeader,
		GetStartedQuiz
	},
	// Setup the template
	setup: () => {
		//  Active States for App and Help menu.
		let helpActive = ref(false);
		let userChoices = ref({});

		// Provide them for other components to alter.
		provide("helpActive", helpActive);
		provide("userChoices", userChoices);

		let windowWidth = ref(window.innerWidth);
		const onWidthChange = () => (windowWidth.value = window.innerWidth);
		onMounted(() => window.addEventListener("resize", onWidthChange));
		onUnmounted(() => window.removeEventListener("resize", onWidthChange));

		provide("windowWidth", windowWidth);

		// Make them available in the current template.
		return { helpActive };
	}
});
</script>

<style scoped>
.app {
	z-index: 10001;
	display: flex;
	flex-direction: column;
	overflow: auto;
	flex: auto;
}

.body {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

/* Fade the app body when the need help is open */
.open--help .body {
	opacity: 0.5;
	pointer-events: none;
}
.open--help .body:after {
	content: "";
	z-index: 10;
	pointer-events: all;
}
</style>
