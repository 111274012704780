import "slick-carousel";


/*
 *   Tab Functions
 */

let $ = jQuery;

let classes = {
  'parent'     : '.j-tabbed',
  'tabs'       : '.j-tabbed__tabs',
  'navigation' : '.j-tabbed__navigation',
  'images'     : '.j-tabbed__images',
}

let blocks = '';

// Setup Function. Public
export function setupTabs() {
  gatherBlocks();
  initBlocks();
}

// Gathers all of the blocks elements
function gatherBlocks() {
  blocks = document.querySelectorAll(classes.parent);
}

// If there are block elements, it setups them up
function initBlocks () {
  if(blocks.length > 0 ){
    blocks.forEach(block => {
      setupBlock(block);
    });
  };
}

// Setup the individual blocks

function setupBlock(block) {
  let tabs = $(block).find(classes.tabs);
  let navigation = $(block).find(classes.navigation);
  let images = $(block).find(classes.images);

  if(elementExists(tabs) && elementExists(images)) {

    let tabArgs = {
      fade :  true,
      arrows: false,
      draggable: false,
      touch: false,
      dots: true,
      customPaging: function(slick, index) {
        var slide = $(slick.$slides[index]);
        var slideTab = slide.find('.wlf-tabbed__tab');
        return '<a class="slick-dot__item">' + slideTab.data('tab-title') + '</a>';
      },
      responsive: [
        {
          breakpoint: 800,
          settings: {
            draggable: true,
            touch: true,
          }
        },
      ]
    };

    if ( images.length > 0 ){
      tabArgs.asNavFor = images;
    }

    if ( navigation.length > 0 ){
      tabArgs.appendDots = navigation;
    }

    let imageArgs = {
      fade :  true,
      arrows: false,
      dots: false,
      draggable: false,
      touch: false,
      asNavFor: tabs,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            draggable: true,
            touch: true,
          }
        },
      ]
    };

    tabs.slick(tabArgs);
    images.slick(imageArgs);
  }
}


/*
 *  Check if an element exisits
 */
function elementExists(element) {
  return typeof element !== "undefined" && element != null;
}