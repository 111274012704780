class UTMTracker {
  constructor() {
    if (UTMTracker.instance) {
      return UTMTracker.instance;
    }
    UTMTracker.instance = this;
    this.utmCodes = {
      fintel_click_id: null,
      productid: null,
      utm_campaign: null,
      utm_source: null,
      utm_term: null,
      utm_medium: null,
      utm_content: null
    };
    this.updateUTMCodes();
  }
  updateUTMCodes() {
    const urlParams = new URLSearchParams(window.location.search);

	for (const code in this.utmCodes) {
		this.utmCodes[code] = sessionStorage.getItem(code);
    }

	urlParams.forEach((value, key) => {
		const lowercaseKey = key.toLowerCase();
		if (lowercaseKey in this.utmCodes) {
			this.utmCodes[lowercaseKey] = value;
			sessionStorage.setItem(lowercaseKey, value);
		}
	});
  }

  getUTMCodes() {
    return this.utmCodes;
  }
}

// Usage example
export const utmTracker = new UTMTracker();
export const utmCodes = utmTracker.getUTMCodes();


function appendUtmCodes(url, utmCodes){
  const queryParams = [];
  for (const [key, value] of Object.entries(utmCodes)) {
    if (value !== null) {
      queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  }
  if (queryParams.length > 0) {
    const queryString = queryParams.join('&');
    return `${url}${url.includes('?') ? '&' : '?'}${queryString}`;
  }
  return url;
}

function appendUtmCodesToLinks(string, utmCodes) {
  const links = document.querySelectorAll(`a[href*="${string}"]`);
  links.forEach((link) => {
    const url = link.href;
    const modifiedUrl = appendUtmCodes(url, utmCodes);
    link.href = modifiedUrl;
  });
}
appendUtmCodesToLinks('illustrations.serenialife.ca', utmCodes);
// console.log(utmCodes);


function appendUtmCodesBeforeHubspotEmbed(utmCodes) {
	if (pageHasHubspotEmbed()) {
		let pageUrlWithUTM = appendUtmCodes(window.location.href, utmCodes);
		history.pushState({ path: pageUrlWithUTM }, '', pageUrlWithUTM);
	}
}

function pageHasHubspotEmbed() {
	let forms = document.querySelectorAll('.hbspt-form');
	return ( forms && forms.length >= 1);
}
