import "lity";
import "slick-carousel";
import { getStartedSetup } from "../util/vue";
import { fitVidsBlocks } from "../util/fitVids";
import { setupTabs } from "../blocks/tabbed";
import { setupStoriesCards, setupLearnList, setupCards } from "./../blocks/cards";
import { gsapSetup } from "../util/gsap";

export default {
	init() {
		// JavaScript to be fired on all pages
		fitVidsBlocks();

		jQuery('.j-slick').slick();

		// Setup Tabs Block
		setupTabs();
		
		// Setup Cards Block
		setupCards();
		setupStoriesCards();
		setupLearnList();

		// Setup Page animations
		gsapSetup();
		
		// Setup Product Picker
		getStartedSetup();	
	},
	finalize() {
		// Copy secondary button in header menu to Mega Menu
        let button = document.querySelector('#menu-main-menu .j-get-started + .button > a');
        if (button === null) {
            return;
        }

		let buttonClone = button.cloneNode(true);
        let megaMenuButtonContainer = document.querySelector('.wlf-mega .wlf-mega--button');
		buttonClone.classList.add('s-button--plain', 'button--secondary');

        if (megaMenuButtonContainer !== null) {
			megaMenuButtonContainer.append(buttonClone);
        }

		// JavaScript to be fired on all pages, after page specific JS is fired
	}
};
