<template>
	<template class="wlf-get-started__path" v-if="entryData.entryCompleted">
		<div
			class="question g-container"
			v-if="currentLevel <= totalLevelCount"
		>
			<get-started-question :currentQuestionData="currentQuestionData" />
		</div>

		<div
			class="wlf-get-started__review"
			v-if="currentLevel > totalLevelCount"
		>
			<get-started-review />
		</div>

		<div class="navigation">
			<get-started-navigation :quizPath="quizPath" />
		</div>
	</template>

	<div class="wlf-get-started__entry" v-if="!entryData.entryCompleted">
		<get-started-entry-point :entryData="entryData" />
	</div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import GetStartedQuestion from "./GetStartedQuestion.vue";
import GetStartedNavigation from "./GetStartedNavigation.vue";
import GetStartedEntryPoint from "./GetStartedEntryPoint.vue";
import GetStartedReview from "./GetStartedReview.vue";

import { utmTracker, utmCodes } from "../scripts/util/utmTracking";

import { ref, provide, computed, watch, inject, nextTick } from "vue";

export default {
	components: {
		GetStartedQuestion,
		GetStartedNavigation,
		GetStartedEntryPoint,
		GetStartedReview
	},

	setup() {
		const quizData = GET_STARTED.quizData; // Quiz Questions/Answers Localized Data (lib/get-started.php)
		const submissionURL = GET_STARTED.submissionURL; // Quiz Questions/Answers Localized Data (lib/get-started.php)
		const hasResultsPage = GET_STARTED.quizData.has_results_page;

		let recommendedProducts = ref([]);

		let entryData = ref({
			directSelectProduct: false,
			selectedProduct: GET_STARTED.selectedProduct,
			entryCompleted: GET_STARTED.quizData.skip_entry ? true : false,
			entryPoint: GET_STARTED.selectedProduct
				? quizData.entry_point["product_confirmation"]
				: quizData.entry_point["default"]
		});

		/*
		 *  Question Path
		 ************/
		let quizPath = computed(() => {
			let path = [];

			let questions = [];
			questions.push(
				entryData.value.directSelectProduct
					? quizData.your_needs["product_select"]
					: quizData.your_needs["goals"]
			);
			if (quizData.your_needs["goals"]["multi_question"]) {
				questions.push("follow_up");
			}
			path.push({
				title: "Your Needs",
				slug: "your-needs",
				questions: questions
			});

			if (
				!entryData.value.directSelectProduct &&
				!quizData.skip_key_questions
			) {
				path.push({
					title: "Key Questions",
					slug: "key-questions",
					questions: quizData.key_questions
				});
			}
			if (!quizData.skip_your_information) {
				path.push({
					title: "Your Information",
					slug: "your-information",
					questions: quizData.your_information
				});
			}
			return path;
		});

		/*
		 *  Current Position
		 ************/
		let totalLevelCount = computed(() => quizPath.value.length);

		let currentLevel = ref(1);
		let currentQuestion = ref(1);

		let currentLevelQuestionCount = computed(() => {
			if (quizPath.value[currentLevel.value - 1]) {
				return quizPath.value[currentLevel.value - 1].questions.length;
			}
			return false;
		});

		let currentQuestionData = computed(() => {
			let questionData = false;

			if (
				quizPath.value[currentLevel.value - 1] &&
				quizPath.value[currentLevel.value - 1].questions[
					currentQuestion.value - 1
				]
			) {
				questionData =
					quizPath.value[currentLevel.value - 1].questions[
						currentQuestion.value - 1
					];

				// If we are on the follow up question we need to manually set it
				// based on the previous answer. This would need to be expanded upon
				// to support follow up questions for other questions beyond 'needs'.
				if (questionData == "follow_up") {
					let previousAnswer = userChoices.value.goals[0];
					let isSet = false;
					quizPath.value[0].questions[0].answers.forEach(answer => {
						if (answer.value == previousAnswer) {
							questionData = answer.follow_up;
							questionData["question_key"] = "goals_follow_up";
							isSet = true;
						}
					});
					if (!isSet) {
						questionData =
							quizPath.value[0].questions[0].answers[0].follow_up;
						questionData["question_key"] = "goals_follow_up";
					}
				}
			}
			return questionData;
		});

		let isLastQuestion = computed(() => {
			return (
				currentQuestion.value == currentLevelQuestionCount.value &&
				currentLevel.value == totalLevelCount.value
			);
		});

		let firstElement = ref(null);
		provide("firstElement", firstElement);

		watch(currentQuestion, (count, prevCount) => {
			focusOnFirstElement();
		});

		watch(currentLevel, (count, prevCount) => {
			focusOnFirstElement();
		});

		let focusOnFirstElement = () => {
			nextTick(function() {
				if (!firstElement.value) {
					return;
				}

				if (Array.isArray(firstElement.value)) {
					if (firstElement.value.length > 0) {
						firstElement.value[0].focus();
					}
				} else {
					firstElement.value.focus();
				}
			});
		};
		provide("currentLevel", currentLevel);
		provide("currentQuestion", currentQuestion);
		provide("currentQuestionData", currentQuestionData);
		provide("totalLevelCount", totalLevelCount);
		provide("currentLevelQuestionCount", currentLevelQuestionCount);

		provide("hasResultsPage", hasResultsPage);

		/*
		 *  Question Navigation
		 ************/
		//  Next Question
		let nextQuestion = function() {
			let gtmData = {
				event_action: "continue",
				event_category: currentQuestionData.value.title,
				event_label: document.location.pathname,
				event: "form tracking",
				customdimension1: entryData.value.directSelectProduct
					? "I have a product in mind"
					: "I'm not sure what I need"
			};

			if (isLastQuestion.value) {
				gtmData.event_action = "review";
			}

			if (currentQuestion.value < currentLevelQuestionCount.value) {
				currentQuestion.value++;
			} else if (
				(hasResultsPage &&
					currentLevel.value <= totalLevelCount.value) ||
				(!hasResultsPage && currentLevel.value < totalLevelCount.value)
			) {
				currentLevel.value = currentLevel.value + 1;
				currentQuestion.value = 1;
			} else {
				submit();

				gtmData.event_action = "complete";
				gtmData.event_category = "almost done";
			}
			// window.dataLayer.filter(event => event.event != 'form tracking');

			window.dataLayer.push(gtmData);
		};
		//  Previous Question
		let previousQuestion = function() {
			if (currentQuestion.value > 1) {
				currentQuestion.value--;
			} else if (currentLevel.value > 1) {
				currentLevel.value--;
				currentQuestion.value = currentLevelQuestionCount.value;
			} else {
				// if(GET_STARTED.quizData.skip_entry) {
				// 	entryData.value.entryCompleted = true;
				// }else{
				// 	entryData.value.entryCompleted = false;
				// }
			}
		};
		//  Jump To Question
		let goToQuestion = function(question, level) {
			currentLevel.value = level;
			currentQuestion.value = question;
		};
		// Provide them for other components to alter.
		provide("nextQuestion", nextQuestion);
		provide("previousQuestion", previousQuestion);
		provide("goToQuestion", goToQuestion);

		let userChoices = inject("userChoices");

		let submit = function() {
			if (GET_STARTED.quizData.skip_your_information) {
				goToResultsPage();
			} else {
				apiSubmit();
			}
		};

		let apiSubmit = function() {
			let data = JSON.parse(JSON.stringify(userChoices.value));
			data = { ...data, ...utmCodes };
			data.action = "get_started_submission";

			axios
				.post(GET_STARTED.ajax_url, qs.stringify(data))
				.then(response => {
					if (response.data.success) {
						goToResultsPage();
					}
				})
				.catch(error => {
					console.log(error);
				});
		};

		let goToResultsPage = function() {
			document.location = getSubmissionURL();
		};

		let getSubmissionURL = function() {
			const url = new URL(submissionURL);
			const searchParams = url.searchParams;
			const hasQueryParams = searchParams.keys().next().done === false;
			let joiner = (hasQueryParams) ? "&" : "?";
			let params = "";

			if (userChoices.value.email) {
				params += joiner + "email=" + userChoices.value.email;
				joiner = "&";
			}
			if (
				typeof userChoices.value.product_select !== "undefined" &&
				userChoices.value.product_select
			) {
				params +=
					joiner +
					"product_selected=" +
					userChoices.value.product_select[0];
				joiner = "&";
			}
			if (
				typeof userChoices.value.question_one !== "undefined" &&
				typeof userChoices.value.question_two !== "undefined"
			) {
				let questionOne = GET_STARTED.quizData.your_needs.goals.answers[userChoices.value.question_one];
				let questionTwo = questionOne.follow_up.answers[userChoices.value.question_two];

				questionOne = questionOne.text.toLowerCase();
				questionTwo = questionTwo.text.toLowerCase();

				questionOne = questionOne.replace(/[^\w\s]/gi, '').replace(/\s+/g, '_');
				questionTwo = questionTwo.replace(/[^\w\s]/gi, '').replace(/\s+/g, '_');

				params +=
					joiner
					+ "question_one=" + questionOne
					+ "&question_two=" + questionTwo;
				joiner = "&";
			}

			let pageLinks = GET_STARTED.page_info;

			if (
				typeof pageLinks.quiz_page !== "undefined" &&
				pageLinks.quiz_page
			) {
				params += joiner + "qp=" + pageLinks.quiz_page;
				joiner = "&";
			}

			if (
				typeof pageLinks.referring_page !== "undefined" &&
				pageLinks.referring_page
			) {
				params += joiner + "rp=" + pageLinks.referring_page;
				joiner = "&";
			}

			return submissionURL + params;
		};

		// Pass variables for the template to use
		return {
			entryData,
			quizPath,
			currentQuestionData,
			totalLevelCount,
			currentLevel,
			submissionURL
		};
	}
};
</script>

<style type="scss">
.question {
	flex: 1 1 auto;
	text-align: center;
	padding: 25px 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.wlf-get-started__entry {
	flex: 1 1 auto;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.wlf-get-started__review {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	flex: 1 1 0;
}
</style>
