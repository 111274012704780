import { gsap } from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(CSSRulePlugin, ScrollToPlugin, ScrollTrigger);

export function gsapSetup() {
	addScrolledClass();

	interiorScrollTriggers();
}

// Add a 'Scrolled' class to body when page has been scrolled
function addScrolledClass() {
	// Add a 'Scrolled' class to body when page has been scrolled past the header
	ScrollTrigger.create({
		trigger: "#wlf-header",
		start: "bottom top",
		endTrigger: "body",
		toggleClass: { targets: "body", className: "is-scrolled" }
	});

	// Add a 'Scrolled' class to body when page has been scrolled past the internal menu
	if (
		document.body.contains(document.querySelector(".j-scroll-anchor__menu"))
	) {
		ScrollTrigger.create({
			trigger: ".j-scroll-anchor__menu",
			start: "bottom top",
			endTrigger: ".wlf-body",
			// end: 'top top',
			toggleClass: {
				targets: "body",
				className: "is-scrolled__inside-menu"
			}
		});
	}
}

// Interior Menu Scroll Triggers
function interiorScrollTriggers() {
	let anchors = document.querySelectorAll(".j-scroll-anchor__block");

	if (anchors.length > 0) {
		anchors.forEach(anchor => {
			let anchorID = anchor.getAttribute("data-anchor");
			let anchorButton = document.querySelectorAll(
				'.j-scroll-anchor__item[href="#' + anchorID + '"'
			);

			let $anchorButton = jQuery(anchorButton);

			ScrollTrigger.create({
				trigger: anchor,
				start: "top 50%",
				endTrigger: "body",
				onToggle: self => {
					if (self.direction > 0) {
						if (!anchorButton) {
							jQuery(".j-scroll-anchor__item.active").removeClass(
								"active"
							);
						} else {
							$anchorButton
								.addClass("active")
								.siblings()
								.removeClass("active");
						}
					}
				}
			});
			ScrollTrigger.create({
				trigger: anchor,
				start: "bottom 50%",
				endTrigger: "body",
				onToggle: self => {
					if (self.direction < 0) {
						if (!anchorButton) {
							jQuery(".j-scroll-anchor__item.active").removeClass(
								"active"
							);
						} else {
							$anchorButton
								.addClass("active")
								.siblings()
								.removeClass("active");
						}
					}
				}
			});
		});

		let first = jQuery(".j-scroll-anchor__item--first");
		ScrollTrigger.create({
			trigger: first,
			start: "top 50%",
			endTrigger: "body",
			onToggle: self => {
				if (self.direction < 0) {
					jQuery(".j-scroll-anchor__item.active").removeClass(
						"active"
					);
				}
			}
		});
	}
}
