import "slick-carousel";


/*
 *   Tab Functions
 */

let $ = jQuery;

let classes = {
  'parent'         : '.j-card-slider',
  'stories'        : '.j-card-slider--stories',
  'learnList'      : '.j-card-slider--learn',
  'cards'          : '.j-card-slider__cards',
  'navigation'     : '.j-card-slider__navigation',
  'dropdownNav'    : '.j-card-slider__navigation--dropdown',
  'arrows'         : '.j-card-slider__arrows',
  'dots'           : '.j-card-slider__dots',
  'controls'       : '.j-card-slider__controls',
  'categoryToggle' : '.j-card-slider__category',
}

let blocks = '';

// Setup Function. Public
export function setupCards() {
  gatherBlocks();
  initBlocks();
}


export function setupStoriesCards() {
  gatherStoriesBlocks();
  initBlocks('stories');
}


// Setup Function. Public
export function setupLearnList() {
  blocks = document.querySelectorAll(classes.learnList);
  initBlocksLearnList();
}


// Gathers all of the blocks elements
function gatherBlocks() {
  blocks = document.querySelectorAll(classes.parent);
}

// Gathers all of the blocks elements
function gatherStoriesBlocks() {
  blocks = document.querySelectorAll(classes.stories);
}

// If there are block elements, it setups them up
function initBlocks (displayStyle) {
  if(blocks.length > 0 ){
    blocks.forEach(block => {
      setupBlock(block, displayStyle);
    });
  };
}

// If there are block elements, it setups them up
function initBlocksLearnList () {
  if(blocks.length > 0 ){
    blocks.forEach(block => {
      setupBlockLearnList(block);
    });
  };
}


// Setup the individual blocks
function setupBlock(block, displayType) {
  let cards = $(block).find(classes.cards);
  let navigation = $(block).find(classes.navigation);
  let dropdownNav = $(block).find(classes.dropdownNav);
  let arrows = $(block).find(classes.arrows);
  let dots = $(block).find(classes.dots);

  if(elementExists(cards)) {
    let args = { };

    if( displayType == "stories" ){
      args = {
        arrows: true,
        dots: true,
        rows: 0,
        appendArrows: arrows,
        appendDots: dots,
      };
    } else {
      args = {
        arrows: true,
        dots: true,
        draggable: !0,
        rows: 0,
        appendArrows: arrows,
        appendDots: dots,
        swipeToSlide: !0, 
        slidesToShow: 4,
        responsive: [
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 1300,
  
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 750,
            settings: { 
              slidesToShow: 1,
            }
          }
        ]
      };
    }
    cards.slick(args);

    if(elementExists(navigation)){
      navigation.on('click', classes.categoryToggle, function(){
        let _this = $(this);
        let category = _this.data('category');
        _this.addClass('active').siblings().removeClass('active');
        cards.addClass('wlf-fadeout');
        
        setTimeout(function(){ 
          cards.slick('slickUnfilter');
          cards.slick('slickFilter', '.wlf-card-slider__card--' + category);
          cards.removeClass('wlf-fadeout');
        }, 175 );
        
        if (elementExists(dropdownNav)) {
          dropdownNav.val(category);
        }

      })
      let active = navigation.find('.active');
      if(active.length > 0 ){
        cards.slick('slickUnfilter');
        cards.slick('slickFilter', '.wlf-card-slider__card--' + active.data('category'));
      }
    }

    if (elementExists(dropdownNav)) {
      dropdownNav.on('change', function () {
        let _this = $(this);
        let category = _this.val();

        console.log(category);

        cards.addClass('wlf-fadeout');

        setTimeout(function(){ 
          cards.slick('slickUnfilter');
          cards.slick('slickFilter', '.wlf-card-slider__card--' + category);
          cards.removeClass('wlf-fadeout');
        }, 175 );

        navigation.children('[data="' + category + '"]').addClass('active').siblings().removeClass('active');
      })
    }
  }
}



// Setup the individual blocks
function setupBlockLearnList(block) {
  let cards = $(block).find(classes.cards);
  let navigation = $(block).find(classes.navigation);
  let arrows = $(block).find(classes.arrows);
  let dots = $(block).find(classes.dots);

  if(! elementExists(cards)) {
    return;
  }

  let args = {
    arrows: true,
    dots: true,
    rows: 0,
    appendArrows: arrows,
    appendDots: dots,
    swipeToSlide: !0, 
    draggable: !0
  };

  if (cards.hasClass('j-card-slider__cards--recent')) {
    args = {
      arrows: false,
      dots: false,
      rows: 0,
      appendArrows: arrows,
      appendDots: dots,        
      swipeToSlide: !0, 
      draggable: !0,
      infinite: false,
      slidesToShow: 3,
    };
  }

  cards.slick(args);
}


/*
 *  Check if an element exisits
 */
function elementExists(element) {
  return typeof element !== "undefined" && element != null;
}