<template>
	<div class="wlf-answers">
		<button
			class="wlf-answer text-h4"
			:class="{
				'is-active': isAnswerActive(
					answer.value ? answer.value : answer.text
				),
				'wlf-answer--small': currentQuestionData.answers.length > 10
			}"
			:data-first-element="answerIndex == 0 ? 'firstElement' : null"
			:ref="answerIndex == 0 ? 'firstElement' : null"
			v-for="(answer, answerIndex) in currentQuestionData.answers"
			v-bind:key="answer.text + '-' + answer.value"
			@click="toggleAnswer(answer, answerIndex)"
			v-text="answer.text ? answer.text : answer.title"
		></button>
	</div>
</template>

<script>
import { ref, inject } from "vue";
export default {
	props: ["currentQuestionData"],
	setup: function(props) {
		let userChoices = inject("userChoices");
		let currentLevel = inject("currentLevel");
		let currentQuestion = inject("currentQuestion");
		let firstElement = inject("firstElement");

		let isAnswerActive = function(answer) {
			if (
				typeof userChoices.value[
					props.currentQuestionData.question_key
				] != "object"
			) {
				userChoices.value[props.currentQuestionData.question_key] = [];
			}
			return (
				typeof userChoices.value[
					props.currentQuestionData.question_key
				] == "object" &&
				userChoices.value[
					props.currentQuestionData.question_key
				].includes(answer)
			);
		};

		let toggleAnswer = function(answer, answerIndex) {
			console.log(currentLevel.value);
			console.log(currentQuestion.value);
			toggleAnswerStorage(
				answer,
				userChoices.value[props.currentQuestionData.question_key]
			);

			if (currentLevel.value == 1) {
				if (currentQuestion.value == 1) {
					userChoices.value.question_one = answerIndex;
				} else if (currentQuestion.value == 2) {
					userChoices.value.question_two = answerIndex;
				}
			}

			toggleRecommendedProducts(answer);
		};

		let toggleAnswerStorage = function(answer, storageLocation) {
			let answerValue = answer.value ? answer.value : answer.text;
			let answerLimit = props.currentQuestionData.maximum_answers
				? props.currentQuestionData.maximum_answers
				: 1;
			let answerIndex = storageLocation.indexOf(answerValue);

			if (answerIndex >= 0) {
				storageLocation.splice(answerIndex, 1);
			} else {
				while (storageLocation.length >= answerLimit) {
					storageLocation.splice(0, 1);
				}
				storageLocation.push(answerValue);
			}
		};

		let toggleRecommendedProducts = function(answer) {
			if (answer.related_product) {
				if (answer.related_product.length > 0) {
					userChoices.value["recommended_product"] = true;
				}
			}
		};
		return { userChoices, toggleAnswer, isAnswerActive, firstElement };
	}
};
</script>

<style type="scss" scoped>
.wlf-answers {
	margin: 35px 0;
}

.wlf-answer {
	background: white;
	padding: 27px 35px;
	border-radius: 10.26px;
	border: none;
	cursor: pointer;
	margin: 15px;
	min-width: 200px;
	transition: all 0.4s ease;
	font-weight: 300;
}
.wlf-answer.is-active {
	color: white;
	background: var(--blue);
}

.wlf-answer:hover,
.wlf-answer:focus {
	transform: scale(1.1);
}

.wlf-answer--small {
	font-size: 17px;
	margin: 7px;
	padding: 10px 20px;
	min-width: 0;
}

/* Question Specific Styling */
.v-quiz__actions--goals .wlf-answers {
	display: flex;
	flex-wrap: wrap;
}
.v-quiz__actions--goals .wlf-answer {
	font-size: 16px;
	width: calc(25% - 30px);
	min-width: 0;
}

@media screen and (max-width: 900px) {
	.wlf-answers {
		margin: 15px 0 0;
	}

	.wlf-answer {
		width: 100%;
		margin: 10px 0;
	}

	.v-quiz__actions--goals .wlf-answer {
		font-size: 16px;
		margin: 10px;
		width: calc(50% - 20px);
	}
}

@media screen and (max-width: 768px) {
	.v-quiz__actions--goals .wlf-answer {
		font-size: 16px;
		margin: 5px 0;
		width: calc(100%);
	}
}
</style>
