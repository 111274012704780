import { createApp } from 'vue'
import App from './../../vue/GetStarted.vue'

/**
 * Setup and append the Product Picker Vue Template
 */
export function getStartedSetup() {
    const app = createApp(App);
    app.mount('#v-get-started');
}

jQuery('[data-get-started]').on('click',function(e){
    e.preventDefault();
    let toggle = jQuery('.j-get-started__toggle');
    if( ! toggle.get(0).checked){
        toggle.trigger('click');
    }
});